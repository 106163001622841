::-webkit-scrollbar {
  display: none;
}
.date-carousel {
  padding-right: 50px;
  padding-left: 50px;
  background-color: rgba(230, 230, 230, 0);
}

.footer-icon {
  font-size: 1em;
  padding: 0.5em;
  margin: 0.5em;
  margin-top: 0;
  transition: 0.5s ease-in-out;
  border-radius: 100%;
  border: 3px solid #eee;
  color: teal;
}

.footer-icon.active {
  transform: scale(1.15) translateY(-0.7em);
  background: linear-gradient(135deg, teal, rgb(10, 255, 255));
  border: 3px solid rgb(0, 95, 117);
  color: white;
}
